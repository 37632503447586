import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
// import "./LikeAndShare.scss";
import { FormattedMessage } from "react-intl";
import { LANGUAGES } from "../../../utils";

class LikeAndShare extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    // this.initFacebookSDK();
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.language !== prevProps.language) {
      // this.initFacebookSDK();
    }
  }
  // initFacebookSDK() {
  // 	if (window.FB) {
  // 		window.FB.XFBML.parse();
  // 	}

  // 	let { language } = this.props;
  // 	let locale = language === LANGUAGES.VI ? "vi_VN" : "en_US";
  // 	window.fbAsyncInit = function () {
  // 		window.FB.init({
  // 			appId: process.env.REACT_APP_FACEBOOK_APP_ID,
  // 			cookie: true, // enable cookies to allow the server to access
  // 			// the session
  // 			xfbml: true, // parse social plugins on this page
  // 			version: "v2.5", // use version 2.1
  // 		});
  // 	};
  // 	// Load the SDK asynchronously
  // 	(function (d, s, id) {
  // 		var js,
  // 			fjs = d.getElementsByTagName(s)[0];
  // 		if (d.getElementById(id)) return;
  // 		js = d.createElement(s);
  // 		js.id = id;
  // 		js.src = `//connect.facebook.net/${locale}/sdk.js`;
  // 		fjs.parentNode.insertBefore(js, fjs);
  // 	})(document, "script", "facebook-jssdk");
  // }
  render() {
    let { width, dataHref, numPost } = this.props;

    return (
      // <div
      // 	class="fb-like"
      // 	// data-href="https://developers.facebook.com/docs/plugins/"
      // 	data-href={dataHref}
      // 	data-width={width ? width : ""}
      // 	data-layout="button_count"
      // 	data-action="like"
      // 	data-size="small"
      // 	data-share="true"
      // > Like</div>

      <div
        class="fb-like"
        data-href="https://developers.facebook.com/docs/plugins/"
        data-width=""
        data-layout="standard"
        data-action="like"
        data-size="small"
        data-share="true"
      ></div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    language: state.app.language,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(LikeAndShare);
