import React, { Component } from "react";
import { connect } from "react-redux";
// import "./About.scss";
import { FormattedMessage } from "react-intl";

class About extends Component {
  render() {
    return (
      <div className="section-share section-about">
        <div className="section-about-header">
          <FormattedMessage id="homepage.about-title" />
        </div>
        <div className="section-about-content">
          <div className="content-left">
            <iframe
              width="100%"
              height="430px"
              src="https://www.youtube.com/embed/hkIMlnlIkaU"
              title="What's Happening with Covid-19 in Vietnam"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <div className="content-right">
            <p>
              <FormattedMessage id="homepage.stauts-decription" />
            </p>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.user.isLoggedIn,
    language: state.app.language,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(About);
