import actionTypes from "./actionTypes";
import {
  getAllCodeService,
  createNewUserService,
  getAllUsers,
  deleteUserService,
  editUserService,
  getTopDoctorHomeService,
  getAllDoctors,
  saveDetailDoctorService,
  getAllSpecialty,
  getAllClinic,
} from "../../services/userService";
import { toast } from "react-toastify";
// export const fetchGenderStart = () => ({
// 	type: actionTypes.FETCH_GENDER_START,
// });
export const fetchGenderStart = () => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: actionTypes.FETCH_GENDER_START });
      let res = await getAllCodeService("GENDER");
      if (res && res.errCode === 0) {
        //
        dispatch(fetchGenderSuccess(res.data));
      } else {
        dispatch(fetchGenderFailed());
      }
    } catch (e) {
      dispatch(fetchGenderFailed());
    }
  };
};

export const fetchGenderSuccess = (genderData) => ({
  type: actionTypes.FETCH_GENDER_SUCCESS,
  data: genderData,
});
export const fetchGenderFailed = () => ({
  type: actionTypes.FETCH_GENDER_FAILED,
});

export const fetchPositionStart = () => {
  return async (dispatch, getState) => {
    try {
      let res = await getAllCodeService("POSITION");
      if (res && res.errCode === 0) {
        //
        dispatch(fetchPositionSuccess(res.data));
      } else {
        dispatch(fetchPositionFailed());
      }
    } catch (e) {
      dispatch(fetchPositionFailed());
    }
  };
};

export const fetchPositionSuccess = (positionData) => ({
  type: actionTypes.FETCH_POSITION_SUCCESS,
  data: positionData,
});
export const fetchPositionFailed = () => ({
  type: actionTypes.FETCH_POSITION_FAILED,
});

export const fetchRoleStart = () => {
  return async (dispatch, getState) => {
    try {
      let res = await getAllCodeService("ROLE");
      if (res && res.errCode === 0) {
        //
        dispatch(fetchRoleSuccess(res.data));
      } else {
        dispatch(fetchRoleFailed());
      }
    } catch (e) {
      dispatch(fetchRoleFailed());
    }
  };
};

export const fetchRoleSuccess = (roleData) => ({
  type: actionTypes.FETCH_ROLE_SUCCESS,
  data: roleData,
});

export const fetchRoleFailed = () => ({
  type: actionTypes.FETCH_ROLE_FAILED,
});

export const createNewUser = (data) => {
  return async (dispatch, getState) => {
    try {
      let res = await createNewUserService(data);

      if (res && res.errCode === 0) {
        toast.success("Create a new user success!!!");
        // toast("🦄 Create a new user success!", {
        // 	position: "top-right",
        // 	autoClose: 5000,
        // 	hideProgressBar: false,
        // 	closeOnClick: true,
        // 	pauseOnHover: true,
        // 	draggable: true,
        // 	progress: undefined,
        // });
        //
        dispatch(saveUserSuccess());
        dispatch(fetchAllUserStart());
      } else {
        dispatch(saveUserFailed());
      }
    } catch (e) {
      dispatch(saveUserFailed());
    }
  };
};

export const saveUserSuccess = () => ({
  type: actionTypes.CREATE_USER_SUCCESS,
});

export const saveUserFailed = () => ({
  type: actionTypes.CREATE_USER_FAILED,
});

export const fetchAllUserStart = () => {
  return async (dispatch, getState) => {
    try {
      let res = await getAllUsers("ALL");
      if (res && res.errCode === 0) {
        //
        dispatch(fetchAllUsersSuccess(res.users.reverse()));
      } else {
        toast.error("Fetch all user failed!!!");
        dispatch(fetchAllUsersFailed());
      }
    } catch (e) {
      toast.error("Fetch all user failed!!!");
      dispatch(fetchAllUsersFailed());
    }
  };
};

export const fetchAllUsersSuccess = (data) => ({
  type: actionTypes.FETCH_ALL_USER_SUCCESS,
  users: data,
});

export const fetchAllUsersFailed = () => ({
  type: actionTypes.FETCH_ALL_USER_FAILED,
});

export const deleteAUser = (userId) => {
  return async (dispatch, getState) => {
    try {
      let res = await deleteUserService(userId);
      //
      if (res && res.errCode === 0) {
        toast.success("Delete a user success!!!");
        dispatch(deleteUserSuccess());
        dispatch(fetchAllUserStart());
      } else {
        // toast.error("Delete a user failed!!!");
        dispatch(deleteUserFailed());
      }
    } catch (e) {
      toast.error("Delete a user failed!!!");
      dispatch(deleteUserFailed());
    }
  };
};

export const deleteUserSuccess = (data) => ({
  type: actionTypes.DELETE_USER_SUCCESS,
  users: data,
});

export const deleteUserFailed = () => ({
  type: actionTypes.DELETE_USER_FAILED,
});

export const editAUser = (data) => {
  return async (dispatch, getState) => {
    try {
      let res = await editUserService(data);

      if (res && res.errCode === 0) {
        toast.success("Edit a user success!!!");
        dispatch(editUserSuccess());
        dispatch(fetchAllUserStart());
      } else {
        // toast.error("Edit a user failed!!!");
        dispatch(editUserFailed());
      }
    } catch (e) {
      toast.error("Edit a user failed!!!");
      dispatch(editUserFailed());
    }
  };
};

export const editUserSuccess = () => ({
  type: actionTypes.EDIT_USER_SUCCESS,
});

export const editUserFailed = () => ({
  type: actionTypes.EDIT_USER_FAILED,
});

export const fetchTopDoctor = () => {
  return async (dispatch, getState) => {
    try {
      let res = await getTopDoctorHomeService("");

      if (res && res.errCode === 0) {
        // toast.success("Fetch Top Doctors success!!!");
        dispatch(fetchTopDoctorSuccess(res.data));
        // dispatch({
        // 	type: actionTypes.FETCH_TOP_DOCTOR_SUCCESS,
        // 	dataDoctors: res.data,
        // });
      } else {
        toast.error("Fetch Top Doctors failed!!!");
        dispatch(fetchTopDoctorFailed());
        // dispatch({
        // 	type: actionTypes.FETCH_TOP_DOCTOR_FAILED,
        // });
      }
    } catch (e) {
      toast.error("Fetch Top Doctors failed!!!");
      dispatch(fetchTopDoctorFailed());
      // dispatch({
      // 	type: actionTypes.FETCH_TOP_DOCTOR_FAILED,
      // });
    }
  };
};

export const fetchTopDoctorSuccess = (data) => ({
  type: actionTypes.FETCH_TOP_DOCTOR_SUCCESS,
  dataDoctors: data,
});

export const fetchTopDoctorFailed = () => ({
  type: actionTypes.FETCH_TOP_DOCTOR_FAILED,
});

export const fetchAllDoctors = () => {
  return async (dispatch, getState) => {
    try {
      let res = await getAllDoctors("");

      if (res && res.errCode === 0) {
        // toast.success("Get All Doctors success!!!");
        dispatch(fetchAllDoctorsSuccess(res.data));
      } else {
        toast.error("Fetch Top Doctors failed!!!");
        dispatch(fetchAllDoctorsFailed());
      }
    } catch (e) {
      toast.error("Fetch Top Doctors failed!!!");
      dispatch(fetchAllDoctorsFailed());
    }
  };
};

export const fetchAllDoctorsSuccess = (data) => ({
  type: actionTypes.FETCH_ALL_DOCTORS_SUCCESS,
  dataDr: data,
});

export const fetchAllDoctorsFailed = () => ({
  type: actionTypes.FETCH_ALL_DOCTORS_FAILED,
});

export const saveDetailDoctor = (data) => {
  return async (dispatch, getState) => {
    try {
      let res = await saveDetailDoctorService(data);

      if (res && res.errCode === 0) {
        toast.success("Save Detail Doctor success!!!");
        dispatch(saveDetailDoctorSuccess());
      } else {
        //
        toast.error("Save Detail Doctor failed!!!");
        dispatch(saveDetailDoctorFailed());
      }
    } catch (e) {
      toast.error("Save Detail Doctor failed!!!");
      dispatch(saveDetailDoctorFailed());
    }
  };
};

export const saveDetailDoctorSuccess = (data) => ({
  type: actionTypes.SAVE_DETAIL_DOCTORS_SUCCESS,
});

export const saveDetailDoctorFailed = () => ({
  type: actionTypes.SAVE_DETAIL_DOCTORS_FAILED,
});

export const fetchAllScheduleTime = () => {
  return async (dispatch, getState) => {
    try {
      let res = await getAllCodeService("TIME");

      if (res && res.errCode === 0) {
        // toast.success("Get All Doctors success!!!");
        dispatch(fetchAllScheduleTimeSuccess(res.data));
      } else {
        toast.error("Fetch Top Doctors failed!!!");
        dispatch(fetchAllScheduleTimeFailed());
      }
    } catch (e) {
      toast.error("Fetch Top Doctors failed!!!");
      dispatch(fetchAllScheduleTimeFailed());
    }
  };
};

export const fetchAllScheduleTimeSuccess = (data) => ({
  type: actionTypes.FETCH_ALLCODE_SCHEDULE_TIME_SUCCESS,
  dataTime: data,
});

export const fetchAllScheduleTimeFailed = () => ({
  type: actionTypes.FETCH_ALLCODE_SCHEDULE_TIME_FAILED,
});

export const getRequiredDoctorInfor = () => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: actionTypes.FETCH_REQUIRED_DOCTOR_INFOR_START });
      let resPrice = await getAllCodeService("PRICE");
      let resPayment = await getAllCodeService("PAYMENT");
      let resProvince = await getAllCodeService("PROVINCE");
      let resSpecialty = await getAllSpecialty();
      let resClinic = await getAllClinic();

      if (
        resPrice &&
        resPrice.errCode === 0 &&
        resPayment &&
        resPayment.errCode === 0 &&
        resProvince &&
        resProvince.errCode === 0 &&
        resSpecialty &&
        resSpecialty.errCode === 0 &&
        resClinic &&
        resClinic.errCode === 0
      ) {
        //
        let data = {
          resPrice: resPrice.data,
          resPayment: resPayment.data,
          resProvince: resProvince.data,
          resSpecialty: resSpecialty.data,
          resClinic: resClinic.data,
        };

        dispatch(getRequiredDoctorInforSuccess(data));
      } else {
        dispatch(getRequiredDoctorInforFailed());
      }
    } catch (e) {
      dispatch(getRequiredDoctorInforFailed());
      //
    }
  };
};

export const getRequiredDoctorInforSuccess = (allRequiredData) => ({
  type: actionTypes.FETCH_REQUIRED_DOCTOR_INFOR_SUCCESS,
  data: allRequiredData,
});
export const getRequiredDoctorInforFailed = () => ({
  type: actionTypes.FETCH_REQUIRED_DOCTOR_INFOR_FAILED,
});
